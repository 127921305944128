<template>
  <b-dropdown-item @click="skin = isDark ? 'light' : 'dark'" link-class="d-flex align-items-center">
    <b-link
      class="nav-link"
    >
      <feather-icon
        size="16"
        :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
        class="mr-50"
      />
      <span>Cambia tema</span>
    </b-link>
  </b-dropdown-item>
  <!-- <b-nav-item @click="skin = isDark ? 'light' : 'dark'">
    <feather-icon
      size="21"
      :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
    />
  </b-nav-item> -->
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
// import { computed } from '@vue/composition-api'
import { computed } from 'vue'
// import { BNavItem } from 'bootstrap-vue'
import { BDropdownItem, BLink } from 'bootstrap-vue'

export default {
  components: {
    BDropdownItem,
    BLink,
    // BNavItem,
  },
  props: {
    withText: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
}
</script>

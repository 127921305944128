<template>
  <div class="clearfix mb-0 mt-25">
    <span class="small float-md-left">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <span class="mr-1 small">All rights Reserved</span>
    </span>

    <span class="small float-md-left">
      <span class="mr-1 small">
        <b-link
          class=""
          :to='"/privacy-policy/"+azToken'
          target="_blank"
        >
        privacy
        </b-link>
      </span>
      <span class="mr-1 small">
        <b-link
          class=""
          :to='"/terms-conditions/"+azToken'
          target="_blank"
        >
        termini e condizioni
        </b-link>
      </span>
      <span class="mr-1 small">
        <b-link
          class=""
          :to='"/faq/"+azToken'
          target="_blank"
        >
        FAQ
        </b-link>
      </span>
      <span class="mr-1 small">
        <a
          class=""
          href="https://www.anticorruzione.it/-/whistleblowing"
          target="_blank"
        >
        Segnalazione Esterna
        </a>
      </span>
    </span>

    <span class="float-md-right small d-md-block">
      {{appName}}
      <small class="text-muted">
        cs03
      </small>
    </span>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  data() {
    return {
      creditsLogo: require('@/assets/images/logo/logo-crediti.png'),
      appName: $themeConfig.app.appName,
    }
  },
  computed: {
    azToken() {
      if (localStorage && localStorage.azUuid)
       return localStorage.azUuid
      else if (this.$route.params && this.$route.params.token)
        return this.$route.params.token;
      else
       return "error"
    },
  },
  components: {
    BLink,
    BImg,
  },
}
</script>
